<template>
  <v-container
    id="atualizacoes-mcpse-historico"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-file-document-edit-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Ajustes de MCPSE</div>
          </template>
          <v-row>
            <v-col cols="12">
              <registros-por-tabela-data-table
                :itemsPerPage="10"
                :items="items"
              />
            </v-col>
            <v-col cols="12">
              <logs-ajustes-manuais-tabelas-data-table 
                tipo="mcpse"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AtualizacoesTabelasService from '@/services/AtualizacoesTabelasService';

export default {
  name: 'AtualizacoesMcpseHistorico',
  mixins: [],
  components: {
    RegistrosPorTabelaDataTable: () => import('@/components/general/data-tables/RegistrosPorTabelaDataTable.vue'),
    LogsAjustesManuaisTabelasDataTable: () => import('@/components/geracao-bdgd/atualizacoes-tabelas/LogsAjustesManuaisTabelasDataTable')
  },
  data: () => ({
    items: [],
  }),
  computed: {
  },
  mounted() {
    this.getTabelasMcpse();
  },
  methods: {
    getTabelasMcpse() {
      AtualizacoesTabelasService.getTabelasMcpse()
        .then((response) => {
          this.items = response.data.map((item) => {
            return {
              ...item,
              link: `/geracao-bdgd/atualizacoes-mcpse/visualizar/${item.tabela.toLowerCase()}`
            };
          })
        })
        .catch((error) => {
          this.$toast.error('Erro ao recuperar as tabelas de MCPSE .', '', { position: 'topRight' });
          console.error(error);
        });
    }
  },
};
</script>
