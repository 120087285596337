import APIService from './APIService';

export default {
  getTabelasDda() {
    return APIService.apiCall().get('/atualizacoes-tabelas/get-tabelas/dda');
  },
  getTabelasMcpse() {
    return APIService.apiCall().get('/atualizacoes-tabelas/get-tabelas/mcpse');
  },
  getDados(tabela, limit, filtros = {}) {
    return APIService.apiCall().get(
      `/atualizacoes-tabelas/get-dados/${tabela}?limit=${limit}&filtros=${JSON.stringify(
        filtros
      )}`
    );
  },
  deleteById(tabela, id) {
    return APIService.apiCall().delete(
      `/atualizacoes-tabelas/delete-by-id/${tabela}/${id}`
    );
  },
  salvarDado(tabela, id, coluna, dado, tipoDado) {
    return APIService.apiCall().put(
      `/atualizacoes-tabelas/salvar-dado/${tabela}/${id}`,
      JSON.stringify({
        dado,
        coluna,
        tipoDado
      })
    );
  },
  criar(tabela, dados) {
    return APIService.apiCall().post(
      `/atualizacoes-tabelas/criar/${tabela}`,
      JSON.stringify(dados)
    );
  },
  logs(tipo, limit) {
    return APIService.apiCall().get(
      `/atualizacoes-tabelas/logs/${tipo}?limit=${limit}`
    );
  }
};
